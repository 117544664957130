/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/no-unescaped-entities */
import NextHead from 'next/head'
import { useRouter } from 'next/router'
import { GA_TRACKING_ID } from '../lib/gtag'

const isProduction = process.env.NODE_ENV === 'production'

interface Props {
  children?: JSX.Element[] | JSX.Element
  title: string
  description?: string
}

const Head: React.FC<Props> = ({ children, title, description }: Props) => {
  const { asPath } = useRouter()
  return (
    <NextHead>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta name="title" content={title.length ? `Good Neighbour | ${title}` : 'Good Neighbour'} />
      <link rel="shortcut icon" href="/favicon.png" />
      <meta
        name="description"
        content={
          description || 'Review your apartment and landlord with Good Neighbour. List your apartment and help others'
        }
      />
      <meta name="author" content="Good Neighbour" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`https://goodnbr.co${asPath}`} />
      <meta property="og:title" content={title.length ? `Good Neighbour | ${title}` : 'Good Neighbour'} />
      <meta
        property="og:description"
        content={
          description || 'Review your apartment and landlord with Good Neighbour. List your apartment and help others'
        }
      />
      <meta property="og:image" content="https://ik.imagekit.io/goodneighbour/promo/Social_Frame_3mxjkvBan.jpg" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={`https://goodnbr.co${asPath}`} />
      <meta property="twitter:title" content={title.length ? `Good Neighbour | ${title}` : 'Good Neighbour'} />
      <meta
        property="twitter:description"
        content={
          description || 'Review your apartment and landlord with Good Neighbour. List your apartment and help others'
        }
      />
      <meta property="twitter:image" content="https://ik.imagekit.io/goodneighbour/promo/Social_Frame_3mxjkvBan.jpg" />
      <title>{title.length ? `Good Neighbour | ${title}` : 'Good Neighbour'}</title>
      {/* enable analytics script only for production */}
      {isProduction && (
        <>
          <script
            async
            dangerouslySetInnerHTML={{
              __html: `
            window.__lc = window.__lc || {};
            window.__lc.license = 12902709;
            ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
        `,
            }}
          />
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
          <script
            async
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
            }}
          />
          <noscript>
            <a href="https://www.livechatinc.com/chat-with/12902709/" rel="nofollow">
              Chat with us
            </a>
            , powered by{' '}
            <a href="https://www.livechatinc.com/?welcome" rel="noopener nofollow" target="_blank">
              LiveChat
            </a>
          </noscript>
        </>
      )}
      {children}
    </NextHead>
  )
}

export default Head
