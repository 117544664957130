/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { NextPage } from 'next'
import Image from 'next/image'
import Link from 'next/link'
import { ReactSVG } from 'react-svg'
import { CgChevronRight } from 'react-icons/cg'
import { motion } from 'framer-motion'
import { InView } from 'react-intersection-observer'

import Head from 'components/Head'
import { Container, Heading, Text, Map } from 'components'
import { CustomLink } from 'components/utils/CustomLink'
import { useGetAllReviews } from 'lib/hooks/index'

const Home: NextPage = () => {
  const { listings } = useGetAllReviews()

  return (
    <div className="text-darker bg-white">
      <Head
        title="Home"
        description="Rent Smart. Rest Easy. Review your apartment and landlord with Good Neighbour. List your apartment and help others"
      />
      <div className="pt-0 lg:pt-36 lg:pb-4 relative overflow-hidden">
        <Container className="max-w-screen-xl mb-0 md:mb-16">
          <div className="grid grid-cols-2 gap-0 relative">
            <motion.div
              layout
              className="w-full col-span-2 lg:col-span-1 relative order-2 mb-4 pt-64 md:pt-96 lg:pt-0 text-center lg:text-center z-[30] lg:z-auto"
              initial="hidden"
              animate="visible"
              variants={{
                hidden: {
                  opacity: 0,
                },
                visible: {
                  opacity: 1,
                  transition: {
                    delayChildren: 0.5,
                    staggerChildren: 0.5,
                  },
                },
              }}
            >
              <motion.div
                variants={{
                  hidden: { opacity: 0, y: 5 },
                  visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 1 }}
                className="flex justify-center lg:mb-6 w-full"
              >
                <ReactSVG src="biglogo.svg" title="Good Neighbour" wrapper="span" className="w-full" />
              </motion.div>
              <motion.div
                variants={{
                  hidden: { opacity: 0, y: 5 },
                  visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 1 }}
                className="text-center"
              >
                <Heading
                  as="h1"
                  className="mb-2 lg:mb-4 text-xl lg:text-2xl text-darkbg relative inline-block !tracking-[0.2em] lg:!tracking-[0.35em] !font-bold uppercase"
                >
                  Rent Smart. Rest Easy.
                </Heading>
              </motion.div>
              <motion.div
                variants={{
                  hidden: { opacity: 0, y: 5 },
                  visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 1 }}
              >
                <Text className="inline-block text-base md:text-lg text-darker tracking-tight !leading-[1.7em] mb-2">
                  Share your rental experience to help the next tenant.
                </Text>
              </motion.div>
              <motion.div
                className="flex flex-row items-center justify-center  space-x-4"
                variants={{
                  hidden: { opacity: 0, y: 5 },
                  visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 1 }}
              >
                <Link href="/review" passHref>
                  <motion.a
                    whileHover={{ scale: 1.03 }}
                    transition={{ type: 'spring', bounce: 0.5 }}
                    whileTap={{ scale: 0.95 }}
                    className="text-sm font-oakes bg-violet text-white rounded-full pt-4 pb-3 px-6 md:w-auto transition-shadow
                    duration-500 ease-in-out motion-reduce:transition-none inline-flex items-center mt-4 justify-center tracking-wide no-underline hover:bg-opacity-90 shadow-lg hover:shadow-xl text-center"
                  >
                    <span className="inline-flex">Review a rental unit.</span>
                  </motion.a>
                </Link>
                <Link href="/rentals" passHref>
                  <motion.a
                    whileHover={{ scale: 1.03 }}
                    transition={{ type: 'spring', bounce: 0.5 }}
                    whileTap={{ scale: 0.95 }}
                    className="text-sm font-oakes bg-darkbg text-white rounded-full pt-4 pb-3 px-6 md:w-auto transition-shadow
                    duration-500 ease-in-out motion-reduce:transition-none inline-flex items-center mt-4 justify-center tracking-wide no-underline hover:bg-opacity-90 shadow-lg hover:shadow-xl text-center relative"
                  >
                    Post your rental.
                    <span className="flex h-4 w-4 absolute -top-0.5 -right-0.5">
                      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500 opacity-75"></span>
                      <span className="relative inline-flex rounded-full h-4 w-4 bg-green-500"></span>
                    </span>
                  </motion.a>
                </Link>
              </motion.div>
            </motion.div>
            <div className="w-screen col-span-2 lg:col-span-auto lg:w-2/3 xl:w-full max-w-[900px] order-3 mt-6 lg:mt-0 pb-4 lg:pb-16 lg:text-right absolute -right-6 md:-right-10 lg:-right-32 xl:-right-56 top-32 lg:top-16">
              <motion.div
                className="w-full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, y: -30 }}
                transition={{ duration: 1, delay: 0.5 }}
              >
                <Image
                  className="object-fill"
                  src={'/home/hero-group.png'}
                  width="1632"
                  height="793"
                  alt="Group of illustrated characters"
                  priority
                />
              </motion.div>
            </div>
          </div>
        </Container>
      </div>

      <motion.div
        initial="hidden"
        animate="visible"
        variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
        transition={{ duration: 1, type: 'tween', delay: 2, ease: 'easeInOut' }}
      >
        <Container>
          {listings.length ? (
            <div className="flex-1 flex w-full h-72 lg:h-144 rounded-xl overflow-hidden shadow-xl relative">
              <Map center={{ latitude: 43.6532, longitude: -79.3832, zoom: 11 }} listings={listings} />
            </div>
          ) : null}
          <div className="flex justify-end items-center mb-6">
            <Link href="/explore" passHref>
              <a className="text-center lg:text-left mt-4 font-regular text-darkbg font-oakes no-underline border-b border-darkbg pb-2 block text-sm items-end inline-flex ml-4">
                <span className="mr-2 w-5">
                  <ReactSVG src="/svg/icons/map.svg" />
                </span>
                <span className="relative top-1">Explore more reviews.</span>
              </a>
            </Link>
          </div>
        </Container>
      </motion.div>

      <div className="my-16 lg:mt-36">
        <Container className="max-w-screen-lg">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <motion.div
                className=" space-y-10"
                ref={ref}
                initial={{ opacity: 0 }}
                animate={
                  inView
                    ? {
                        opacity: 1,
                        y: -10,
                      }
                    : { opacity: 0, y: 0 }
                }
                transition={{
                  duration: 0.5,
                }}
              >
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={
                    inView
                      ? {
                          opacity: 1,
                          y: -10,
                        }
                      : { opacity: 0, y: 0 }
                  }
                  transition={{ duration: 0.5 }}
                >
                  <Text className="text-xl lg:text-4xl font-oakes leading-relaxed lg:leading-snug">
                    Housing is a human right yet we treat it as a luxury. Renters are treated like they don't contribute
                    to our community.
                  </Text>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={
                    inView
                      ? {
                          opacity: 1,
                          y: -10,
                        }
                      : { opacity: 0, y: 0 }
                  }
                  transition={{ duration: 0.5, delay: 0.5 }}
                >
                  <Text className=" text-xl lg:text-4xl font-oakes leading-relaxed lg:leading-snug">
                    If people aren’t looking out for the community, then the benefits of a community erode. We work
                    better together.
                  </Text>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={
                    inView
                      ? {
                          opacity: 1,
                          y: -10,
                        }
                      : { opacity: 0, y: 0 }
                  }
                  transition={{ duration: 0.5, delay: 1 }}
                >
                  <Text className="text-xl lg:text-4xl font-oakes leading-relaxed lg:leading-snug">
                    Good Neighbour seeks to revolutionize renting by empowering renters and landlords to be their best.
                  </Text>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={
                    inView
                      ? {
                          opacity: 1,
                          y: -10,
                        }
                      : { opacity: 0, y: 0 }
                  }
                  transition={{ duration: 0.5, delay: 1.5 }}
                >
                  <CustomLink
                    href="/about"
                    className="inline-block no-underline bg-darker text-white pb-2.5 pt-3 px-6 rounded-full font-thin"
                  >
                    Read more about our mission
                    <CgChevronRight className="inline-block relative -top-px" />
                  </CustomLink>
                </motion.div>
              </motion.div>
            )}
          </InView>
        </Container>
      </div>

      <div className="mb-16 lg:mb-36">
        <Container>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <motion.div
                className="text-darkbg pb-8 lg:py-16 flex md:flex-row md:justify-start flex-col relative items-center"
                ref={ref}
                initial={{ opacity: 0 }}
                animate={inView ? { opacity: 1, y: -10 } : { opacity: 0, y: 0 }}
                transition={{ duration: 1 }}
              >
                <div className="w-full lg:w-1/2">
                  <Text className="font-oakes tracking-tight text-xl">Verified by the community.</Text>
                  <Heading as="h2" className="text-3xl lg:text-5xl !font-semibold mt-2 lg:mt-4 !leading-tight">
                    Apartment listings don’t tell you the full story.
                  </Heading>
                  <Text className="text-base tracking-tight !leading-[1.7em] lg:mt-6 lg:mb-6">
                    Listings are designed to sell, but the community has the lived experiences that come from spending
                    time in an apartment. Noisy neighbours, accessibility, building maintainance and more. These details
                    can only come from the community. Shout it out, anonymously.
                  </Text>
                </div>
                <motion.div
                  className="relative lg:absolute -right-2 lg:right-16  w-full md:w-3/4 lg:w-2/5"
                  initial={{ opacity: 0 }}
                  animate={inView ? { opacity: 1, y: -30 } : { opacity: 0, y: 0 }}
                  transition={{ duration: 1, delay: 0.5 }}
                >
                  <Image
                    className="object-fill"
                    src={'/home/details.png'}
                    width="1147"
                    height="816"
                    alt="Examples of listing details"
                    priority
                  />
                </motion.div>
              </motion.div>
            )}
          </InView>
        </Container>
      </div>

      <div className="mb-0 lg:mb-24">
        <Container>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <motion.div
                className="rounded-xl flex md:flex-row md:justify-start flex-col relative items-start relative"
                ref={ref}
                initial={{ opacity: 0 }}
                animate={inView ? { opacity: 1 } : { opacity: 0 }}
                transition={{ duration: 1 }}
              >
                <div className="lg:w-1/2">
                  <Text className="font-oakes tracking-tight text-xl">Help others.</Text>
                  <Heading as="h2" className="text-3xl lg:text-5xl !font-semibold mt-4 !leading-none">
                    Be a voice. Not an echo.
                  </Heading>
                  <div className="lg:relative left-6 lg:w-5/6 mt-6 lg:mt-0">
                    <Image
                      className="object-fill"
                      src={'/home/hero-moped.png'}
                      width="1059"
                      height="1080"
                      alt="Character riding a moped and holding an iPad"
                      priority
                    />
                  </div>
                </div>
                <div className="lg:w-1/2 pt-10 lg:pt-0">
                  <Heading as="h3" className="text-lg !font-semibold mb-3 !leading-none !tracking-normal">
                    Renters deserve respect.
                  </Heading>
                  <Text className="text-base tracking-tight !leading-[1.7em] mb-10">
                    Renting is often your largest monthly expense and yet, we struggle to find housing that matches our
                    expectations. As consumers, society treats us like we don't deserve the respect we should have.
                  </Text>
                  <Heading as="h3" className="text-lg !font-semibold mb-3 !leading-none !tracking-normal">
                    We need each other.
                  </Heading>
                  <Text className="text-base tracking-tight !leading-[1.7em] mb-10">
                    Getting that first utility bill can be a shock. Find out about the average costs previous tenants
                    have paid for utilities and more. You deserve the best.
                  </Text>
                  <Heading as="h3" className="text-lg !font-semibold mb-3 !leading-none !tracking-normal">
                    Protect others with your voice.
                  </Heading>
                  <Text className="text-base tracking-tight !leading-[1.7em] mb-6">
                    We review everything else. Clothes, shoes, computers and yet apartments are often a roll of the
                    dice. Telling your story helps others find safe and healthy housing.
                  </Text>
                </div>
              </motion.div>
            )}
          </InView>
        </Container>
      </div>

      {/* <div className="bg-gradient-to-r from-mint1 to-blue-50 py-12 lg:py-16">
        <Container className="max-w-screen-xl">
          <div className="w-full">
            <Text className="font-oakes text-4xl text-darkbg tracking-tight font-semibold">
              Recently added reviews.
            </Text>
            <Text className="text-xs text-smoke-900">
              The content below contains views, experiences and opinions that are solely those of the individuals who
              have submitted them.
            </Text>
            <div className="w-full flex flex-wrap flex-col sm:flex-row space-y-2 sm:space-y-0 mt-4 md:mt-8 sm:px-4 md:px-0 items-start md:items-start content-between">
              {reviews?.slice(0, 3).map((review, i) => (
                <div key={review.id} className="w-full lg:w-1/3 flex-grow-0 lg:pr-4 pb-4 z-7500">
                  <CustomLink
                    href={
                      review.category === 'company' ? `management/${review.company.id}` : `listing/${review.listing.id}`
                    }
                    className="block no-underline"
                  >
                    <motion.div
                      className="bg-white flex-auto md:py-4 md:px-5 py-4 px-3 rounded-xl relative hover:shadow-lg transition-shadow duration-500 border border-smoke-50 shadow-md"
                      whileHover={{ scale: 1.01 }}
                      transition={{ type: 'spring', bounce: 0.5 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <div className="flex items-start">
                        <div className="text-smoke-500 mr-2 w-4 rounded-full">
                          <ReactSVG src="/svg/icons/pin.svg" />
                        </div>
                        <div className="flex-1">
                          <Text className="block no-underline hover:underline overflow-ellipsis overflow-hidden text-sm mt-0.5 font-semibold">
                            {review.category === 'company'
                              ? review.company.city
                                ? `${review.company.name} - ${review.company.city}`
                                : review.company.name
                              : formatAddress(review.listing)}
                          </Text>
                          <div className="flex items-center mt-1 mb-3">
                            {review.rating ? (
                              <Rating value={review.rating} type="Review" canPluralize={true} className="text-violet" />
                            ) : null}
                            {review.rentPrice ? (
                              <Text className="text-xs text-smoke-600 relative top-px ml-1 font-sans font-medium">
                                Average Rent: ${review.rentPrice}
                              </Text>
                            ) : null}
                          </div>
                          {review.conciseReview ? (
                            <Text className="text-sm text-darkbg font-sans mt-2">{review.conciseReview}...</Text>
                          ) : null}
                        </div>
                      </div>
                    </motion.div>
                  </CustomLink>
                </div>
              ))}
            </div>
          </div>
        </Container>
      </div> */}

      <div className="py-32 text-center text-darkbg">
        <Heading as="h2" className="text-darkbg text-5xl mb-3 !font-bold">
          Get started.
        </Heading>
        <Text className="text-base md:text-xl tracking-tight !leading-[1.7em]">
          Review your rental experience to help others.
        </Text>
        <Link href="/review" passHref>
          <motion.a
            whileHover={{ scale: 1.03 }}
            transition={{ type: 'spring', bounce: 0.5 }}
            whileTap={{ scale: 0.95 }}
            className="font-oakes bg-violet-500 text-white rounded-full pt-4 pb-3 px-16 sm:px-10 md:w-auto transition-shadow
                    duration-500 ease-in-out motion-reduce:transition-none inline-flex items-center mt-4 justify-center tracking-wide no-underline hover:bg-opacity-80 shadow-lg"
          >
            <span className="inline-flex">Tell your story.</span>
          </motion.a>
        </Link>
      </div>

      <div className="py-16 bg-smoke-50">
        <Container className="max-w-screen-lg text-center">
          <Text className="text-darker leading-[1.9em] font-oakes">
            Good Neighbour recognizes that it was founded on the traditional territory of many nations including the
            Mississaugas of the Credit, the Anishnabeg, the Chippewa, the Haudenosaunee and the Wendat peoples and is
            now home to many diverse First Nations, Inuit and Métis peoples. Good Neighbour recognizes that Hamilton is
            covered by Treaty 3 signed with the Mississaugas of the Credit.
          </Text>
        </Container>
      </div>
    </div>
  )
}

export default Home
