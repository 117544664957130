import useSWR from 'swr'
import { fetcher } from 'lib/fetcher'

export function useGetListingById(id: string) {
  const { data, error } = useSWR(id ? `listings/${id}` : null, fetcher)
  return {
    listing: data,
    isLoading: !error && !data,
    isError: error,
  }
}
