import useSWR from 'swr'
import { fetcher } from 'lib/fetcher'

export function useGetManagementById(id: string) {
  const { data, error } = useSWR(id ? `company/${id}` : null, fetcher)
  return {
    company: data,
    isLoading: !error && !data,
    isError: error,
  }
}
