import useSWR from 'swr'
import { fetcher } from 'lib/fetcher'

export function useGetUserById(id: string) {
  const { data, error } = useSWR(id ? `user/${id}` : null, fetcher)

  return {
    account: data?.data || [],
    isLoading: !error && !data,
    isError: error || !data?.success,
  }
}
