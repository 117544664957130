import useSWR from 'swr'
import { fetcher } from 'lib/fetcher'

export function useGetAllReviews() {
  const { data, error } = useSWR(`content/all`, fetcher)

  return {
    listings: data?.data || [],
    isLoading: !error && !data,
    isError: error || !data?.success,
  }
}
